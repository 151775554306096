import React from "react"
import * as Icon from "react-feather"
import * as constant from "./constant"
import {ROUTE_CREATE_ADMIN, ROUTE_FAV_RECIPES, ROUTE_PACKAGE_ALLOW, ROUTE_PROFILE, ROUTE_RECIPES_ADD} from "./constant";

const navigationConfig = [
  {
    id: "1",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Monitor size={20}/>,
    permissions: ["admin"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_DASHBOARD
  },
  {
    id: "2",
    title: "Manage Users",
    type: "item",
    icon: <Icon.Users size={20}/>,
    permissions: ["admin"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_USERS
  },
  {
    id: "3",
    title: "Manage Packages",
    type: "item",
    icon: <Icon.FolderMinus size={20}/>,
    permissions: ["admin"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_PACKAGES,
    // children: [
    //   {
    //     id: "15",
    //     title: "Vendor Package",
    //     type: "item",
    //     icon: <Icon.Circle size={12} />,
    //     permissions: ["admin"],
    //     navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_PACKAGES,
    //   },
    //   {
    //     id: "16",
    //     title: "Storage Package",
    //     type: "item",
    //     icon: <Icon.Circle size={12} />,
    //     permissions: ["admin"],
    //     navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_PACKAGE_ALLOW,
    //   }
    // ]
  },
  {
    id: "6",
    title: "Manage Shops",
    type: "item",
    icon: <Icon.Coffee size={20}/>,

    permissions: ["admin"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_SHOP
  },
  {
    id: "8",
    title: "Settings",
    type: "collapse",
    icon: <Icon.Settings size={20}/>,
    permissions: ["admin"],
    // navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_PACKAGES,
    children: [
      {
        id: "9",
        title: "Create Admin",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin"],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CREATE_ADMIN,
      },
      {
        id: "11",
        title: "Profile Details",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin"],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_PROFILE,
      },
      {
        id: "10",
        title: "Change Password",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin"],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CHANGE_PASSWORD,
      }
    ]
  },
];

export default navigationConfig

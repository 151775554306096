/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 4/20/20
 * Time: 1:56 PM
 */
import swal from "sweetalert";
import toastr from 'toastr';
import Cookies from "js-cookie";
import * as constant from "../configs/constant";
import React from "react";

export const sureTxt = "Are you sure you want to do this?";

export const notifyMessage = (msg, type, duration) => {
  let msgType = "error";

  if (type === 0) {
    msgType = "error"
  } else if (type === 1) {
    msgType = "success"
  } else if (type === 2) {
    msgType = "warning"

  }
  toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "2500",
    "hideDuration": "2500",
    "timeOut": duration === undefined ? "2500" : duration,
    "extendedTimeOut": "2500",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  };
  toastr[msgType](msg === undefined || msg === null ? "Please enter correct details" : msg, type === 0 ? 'Error' : type === 1 ? 'Success' : type === 2 ? 'Warning' : "Error")
};
export const onFileError = (error) => {
  notifyMessage(error.message);
};

export function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const warningAlert = (msg, type, redirect) => {
  swal({
    title: msg,
    // icon: type === 0 ? 'success' : 'error',
    closeOnClickOutside: false,
    buttons: {
      dangerMode: {
        text: "Okay",
        value: "action",
        className: "okay-btn"
      }
    }
  })
    .then((value) => {
      switch (value) {

        case "action":
          break;
        default:
      }
    })
};

export const formatCurrency = (val) => {
  val = val === undefined ? 0 : val;
  // return val.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '1,');
  return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const checkAuthHandler = async () => {
  let isAuth = await Cookies.get(constant.ACCESS_TOKEN);
  return !!isAuth;
};
export const compressImage = async (files) => {

};
export const getTableHeader = (status) => {
  if(status === "DASHBOARD_USERS") {
    return <tr>
      <th>Member Name</th>
      <th>Email</th>
      <th>Phone Number</th>
      <th>Date of Registration</th>
    </tr>;
  }else if(status === "ALLOW_ZOOM") {
    return <tr>
      {/*<th>Package Name</th>*/}
      <th>Online Schedule Name</th>
      <th>Action</th>
    </tr>;
  }else if(status === "ALLOW_WORKOUT") {
    return <tr>
      {/*<th>Package Name</th>*/}
      <th>Workout Name</th>
      <th>Action</th>
    </tr>;
  }else if(status === "ALLOW_RECIPE") {
    return <tr>
      {/*<th>Package Name</th>*/}
      <th>Recipe Name</th>
      <th>Action</th>
    </tr>;
  }else if(status === "SHOP") {
    return <tr>
      <th>Image</th>
      <th>Shop Name</th>
      <th>Item Name</th>
      <th>Price</th>
      <th>Status</th>
      <th>Vendor</th>
      <th>Details</th>
      <th>Action</th>
    </tr>;
  }else if(status === "WORKOUT_VIDEO"){
    return <tr>
      <th>Image</th>
      <th>Video Name</th>
      <th>Workout</th>
      <th>Video URL</th>
      <th>Video Duration</th>
      <th>Recommend</th>
      <th>Watched Count</th>
      <th>Favourite Count</th>
      <th>Status</th>
      <th>Update</th>
      <th>Action</th>
    </tr>;
  }else if(status === "RECIPES") {
    return <tr>
      <th>Image</th>
      <th>Name</th>
      {/*<th>Description</th>*/}
      <th>Meal Type</th>
      <th>Preparing Time</th>
      <th>Calories</th>
      <th>Rating</th>
      <th>Vegetarian</th>
      <th>Status</th>
      <th>Update</th>
      {/*<th>Active/ Inactive</th>*/}
    </tr>;
  }else if(status === "WORKOUTS") {
    return <tr>
      <th>Image</th>
      <th>Name</th>
      <th>Description</th>
      <th>Rating</th>
      <th>Status</th>
      <th>Linked Videos</th>
      <th>Update</th>
      <th>Action</th>
    </tr>;
  }else if(status === "PACKAGES") {
    return <tr>
      <th>Image</th>
      <th>Name</th>
      <th>Duration</th>
      <th>Type</th>
      <th>Size</th>
      <th>Price</th>
      <th>Status</th>
      <th>Subscribers</th>
      <th>Update</th>
      {/*<th>Action</th>*/}
    </tr>;
  }else if(status === "ADMIN_USER") {
    return <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Gender</th>
      {/*<th>Weight</th>*/}
      {/*<th>Height</th>*/}
      <th>DOB</th>
      <th>Status</th>
      <th>Update</th>
      {/*<th className={"align-center_"}>Action</th>*/}
    </tr>;
  }else if(status === "V") {
    return <tr>
      <th>Name</th>
      <th>Email</th>
      <th>City</th>
      <th>Mobile</th>
      <th>User Storage</th>
      <th>Status</th>
      <th className={"align-center m-auto"}>Subscribed Packages</th>
      <th className={"align-center m-auto"}>Shop Items</th>
      {/*<th className={"align-center_"}>Action</th>*/}
    </tr>;
  }else if(status === "N") {
    return <tr>
      <th>Name</th>
      <th>Email</th>
      <th>City</th>
      <th>Mobile</th>
      <th>User Storage</th>
      <th>Status</th>
      <th className={"align-center m-auto"}>Subscribed Packages</th>
      {/*<th className={"align-center m-auto"}>Shop Items</th>*/}
      {/*<th className={"align-center_"}>Action</th>*/}
    </tr>;
  }

};
export const getBase64FromUrl = async (url) => {
  console.log("read...",url)
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function() {
      const base64data = reader.result;
      resolve(base64data);
    }
  });
}

export const ACCESS_TOKEN = "elephant.admin.access";
export const ADMIN_ID = "elephant.admin.id";

export const BASE_ROUTE_PATH = "";
// export const BASE_ROUTE_PATH = "/elephant";

export const ROUTE_DASHBOARD = "/";
export const ROUTE_LOGIN = "/login";

export const ROUTE_RECIPES_ADD = "/recipes/add";
export const ROUTE_RECIPES_MANAGE = "/recipes/manage-all";
export const ROUTE_USERS = "/users";
export const ROUTE_PACKAGES = "/packages";
export const ROUTE_PACKAGE_ALLOW = "/packages/allocation";
export const ROUTE_SHOP = "/manage-shop";
export const ROUTE_ARCHIVE = "/archive-zoom-packages";

export const ROUTE_WORKOUT = "/workouts";

export const ROUTE_SUB_WORKOUT = "/workouts-videos";

export const ROUTE_CREATE_ADMIN = "/settings/manage-admin";
export const ROUTE_PROFILE = "/settings/profile";
export const ROUTE_CHANGE_PASSWORD = "/settings/change-password";

export const ROUTE_FAV_RECIPES = "/favorite/recipes";
export const ROUTE_FAV_WORKOUTS = "/favorite/workouts-videos";

export const JOBS_CONST = "jobs";
export const DEALS_CONST = "deals";
export const NEWS_CONST = "news";

export const HIDE_STS = "HIDDEN";
export const SHOW_STS = "SHOW";
export const DELETED_STS = "DELETED";
